<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">证书管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">证书列表</a>
          <i>></i>
          <a href="javascript:;">学员列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="searchboxItem">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="certApplyPerson"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入姓名"
                clearable
              />
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="certApplyIdcard"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入身份证号"
                clearable
              />
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">等级:</span>
              <el-select
                v-model="certApplyLevel"
                placeholder="请选择"
                clearable
                filterable
                size="small"
              >
                <el-option
                  v-for="item in MiniLevelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <el-button class="bgc-bv" type="primary" @click="getData()">查询</el-button>
              <!-- <el-button type="primary" @click="EditDialog('add')"
                >新增</el-button
              > -->
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :header-cell-style="tableHeader"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="级别"
                align="center"
                prop="certApplyLevelStr"
              />
              <el-table-column
                label="姓名"
                align="center"
                prop="certApplyPerson"
              />
              <el-table-column
                label="身份证号"
                align="center"
                prop="certApplyIdcard"
              />
              <el-table-column
                label="手机号码"
                align="center"
                prop="certApplyPhone"
                show-overflow-tooltip
              />
              <el-table-column
                label="学历"
                align="center"
                prop="certApplyEducation"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("EDUCATION", scope.row.certApplyEducation)
                  }}
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="证件照片"
                align="center"
                prop="certCoverUrl"
              >
                <template slot-scope="scope">
                  <el-image
                    style="width: 30px; height: 30px; border-radius: 50%"
                    :src="scope.row.certApplyIdcardFront"
                    :preview-src-list="[
                      scope.row.certApplyIdcardFront,
                      scope.row.certApplyIdcardBack,
                    ]"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-folder-delete"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column> -->
              <el-table-column
                label="报名日期"
                align="center"
                prop="createTime"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="EditDialog('edit', scope.row)"
                    >查看</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @getData="getData"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <el-dialog
      :title="DialogTitle"
      :visible.sync="DialogVisible"
      width="50%"
      center
      @close="DialogCancel"
    >
      <el-form
        ref="DialogFromRef"
        :model="DialogFrom"
        :rules="DialogFromRules"
        label-width="120px"
        size="small"
      >
        <el-form-item label="证书名称：" prop="certApplyName" >
          <el-input v-model="DialogFrom.certApplyName" disabled></el-input>
        </el-form-item>
        <el-form-item label="证书级别：" prop="certApplyLevel">
          <el-select
            v-model="DialogFrom.certApplyLevel"
            placeholder="请选择"
            clearable
            filterable
            size="small"
          >
            <el-option
              v-for="item in MiniLevelList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名：" prop="certApplyPerson">
          <el-input v-model="DialogFrom.certApplyPerson"></el-input>
        </el-form-item>
        <el-form-item label="身份证号：" prop="certApplyIdcard">
          <el-input v-model="DialogFrom.certApplyIdcard"></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="certApplyPhone">
          <el-input v-model="DialogFrom.certApplyPhone"></el-input>
        </el-form-item>
        <el-form-item label="学历：" prop="certApplyEducation">
          <el-select
            v-model="DialogFrom.certApplyEducation"
            placeholder="请选择"
            clearable
            filterable
            size="small"
          >
            <el-option
              v-for="item in educationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证正面：" prop="certApplyIdcardFrontKey">
          <el-upload
            :on-change="handlebusinessLicense"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
              :src="
                DialogFrom.certApplyIdcardFrontKey ||
                require('@/assets/develop.png')
              "
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
        </el-form-item>
        <el-form-item label="身份证反面：" prop="certApplyIdcardBackKey">
          <el-upload
            :on-change="handlebusinessLicense1"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
              :src="
                DialogFrom.certApplyIdcardBackKey ||
                require('@/assets/develop.png')
              "
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
        </el-form-item>
        <el-form-item label="毕业证：" prop="certApplyGraduationCertKey">
          <el-upload
            :on-change="handlebusinessLicense2"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
              :src="
                DialogFrom.certApplyGraduationCertKey ||
                require('@/assets/develop.png')
              "
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
        </el-form-item>

        <el-form-item label="寸照：" prop="certPhoto">
          <el-upload
            :on-change="handlebusinessLicense3"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
              :src="
                DialogFrom.certApplyPhotoKey || require('@/assets/develop.png')
              "
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogCancel">取 消</el-button>
        <el-button type="primary" @click="DialogSure()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import List from "@/mixins/List";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
let editor = {};
export default {
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data: function () {
    return {
      certApplyPerson: "",
      certApplyIdcard: "",
      certApplyLevel: "",
      DialogTitle: "查看学员",
      DialogVisible: false,
      educationList: [], //学历
      MiniLevelList: [], //等级
      DialogFrom: {
        certApplyName: "",
        certApplyLevel: "",
        certApplyPerson: "",
        certApplyIdcard: "",
        certApplyPhone: "",
        certApplyEducation: "",
        certApplyIdcardFrontKey: "", //身份证正面
        certApplyIdcardBackKey: "", //身份证反面
        certApplyGraduationCertKey: "", //毕业证
        certApplyPhotoKey: "", //寸照
      },
      DialogFromRules: {
        certApplyName: [
          { required: true, message: "请输入证书名称", trigger: "blur" },
        ],
        certApplyLevel: [
          { required: true, message: "请选择证书级别", trigger: "change" },
        ],
        certApplyPerson: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        certApplyIdcard: [
          { required: true, message: "请输入身份证号", trigger: "change" },
        ],
        certApplyPhone: [
          { required: true, message: "请输入手机号码", trigger: "change" },
        ],
        certApplyEducation: [
          { required: true, message: "请选择学历", trigger: "change" },
        ],
        certApplyIdcardFrontKey: [
          { required: true, message: "请上传身份证正面", trigger: "change" },
        ],
        certApplyIdcardBackKey: [
          { required: true, message: "请上传身份证反面", trigger: "change" },
        ],
      },
    };
  },
  mounted() {},
  created() {
    this.getEducationList();
  },
  methods: {
    /* 身份证正面 */
    handlebusinessLicense(res) {
      this.uploadFetch(res.raw);
    },
    uploadFetch(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.DialogFrom.certApplyIdcardFrontKey = ret.data.fileURL || "";
        that.DialogFrom.certApplyIdcardFront = ret.data.fileKey || "";
      });
    },
    /* 身份证反面 */
    handlebusinessLicense1(res) {
      this.uploadFetch1(res.raw);
    },
    uploadFetch1(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.DialogFrom.certApplyIdcardBack = ret.data.fileKey || "";
        that.DialogFrom.certApplyIdcardBackKey = ret.data.fileURL || "";
      });
    },
    /* 毕业证 */
    handlebusinessLicense2(res) {
      this.uploadFetch2(res.raw);
    },

    uploadFetch2(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.DialogFrom.certApplyGraduationCert = ret.data.fileKey || "";
        that.DialogFrom.certApplyGraduationCertKey = ret.data.fileURL || "";
      });
    },
    /* 寸照*/
    handlebusinessLicense3(res) {
      this.uploadFetch3(res.raw);
    },
    uploadFetch3(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.DialogFrom.certApplyPhoto = ret.data.fileKey || "";
        that.DialogFrom.certApplyPhotoKey = ret.data.fileURL || "";
      });
    },
    //码值获取信息
    getEducationList() {
      const evaluateEducationList = this.$setDictionary("EDUCATION", "list");
      const LevelList = this.$setDictionary("MINI_CERT_LEVEL", "list");

      for (const key in evaluateEducationList) {
        this.educationList.push({
          value: key,
          label: evaluateEducationList[key],
        });
      }
      for (const key in LevelList) {
        this.MiniLevelList.push({
          value: key,
          label: LevelList[key],
        });
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        certId:this.$route.query.certId
      };
      if (this.certApplyPerson) {
        params.certApplyPerson = this.certApplyPerson;
      }
      if (this.certApplyIdcard) {
        params.certApplyIdcard = this.certApplyIdcard;
      }
      if (this.certApplyLevel) {
        params.certApplyLevel = this.certApplyLevel;
      }
      this.doFetch(
        {
          url: "/minicert/certapply/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },

    /* 新增||编辑 */
    EditDialog(stu, row) {
      this.DialogVisible = true;
      if (stu == "edit") {
        this.DialogTitle = "查看学员";
        this.getInfo(row.certApplyId);
      }
    },
    getInfo(certApplyId) {
      this.$post(
        "/minicert/certapply/getInfo",
        {
          certApplyId,
        },
        3000,
        true,
        2
      )
        .then((ret) => {
          this.DialogFrom = {
            ...this.DialogFrom,
            ...ret.data,
          };
        })
        .catch((err) => {
          return;
        });
    },
    change(val) {
      let obj = {};
      obj = this.courseTypeTree.find((item) => {
        //遍历list的数据
        return item.certCategoryId === val; //筛选出匹配数据
      });
      console.log(obj);
      this.DialogFrom.certCategoryName = obj.certCategoryName;
    },
    //新增确认
    DialogSure() {
      this.$refs.DialogFromRef.validate((valid) => {
        if (valid) {
          let data = {
            certApplyName: this.DialogFrom.certApplyName,
            certApplyLevel: this.DialogFrom.certApplyLevel,
            certApplyPerson: this.DialogFrom.certApplyPerson,
            certApplyIdcard: this.DialogFrom.certApplyIdcard,
            certApplyPhone: this.DialogFrom.certApplyPhone,
            certApplyEducation: this.DialogFrom.certApplyEducation,
            certApplyIdcardFront: this.DialogFrom.certApplyIdcardFront, //身份证正面
            certApplyIdcardBack: this.DialogFrom.certApplyIdcardBack, //身份证反面
            certApplyGraduationCert: this.DialogFrom.certApplyGraduationCert, //毕业证
            certApplyPhoto: this.DialogFrom.certApplyPhoto, //寸照
          };
          if (this.DialogFrom.certApplyId) {
            data.certApplyId = this.DialogFrom.certApplyId;
          }
          this.$post(
            this.DialogFrom.certApplyId
              ? "/minicert/certapply/modify"
              : "/minicert/certapply/insert",
            data,
            3000,
            true,
            2
          )
            .then((ret) => {
              if (ret.status == 0) {
                this.$message.success("保存成功");
                this.empty();
                this.DialogVisible = false;
                this.getData(-1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    //新增取消
    DialogCancel() {
      this.empty();
      this.DialogVisible = false;
    },
    empty() {
      this.DialogFrom = {
        certName: "", //证书名称
        certType: "", //证书分类
        certCategoryName: "",
        certPrice: "", //市场价格
        recruitStudentsTime: "", //招生时间
        certIntroId: "", //证书展示页
        certCover: "", //简介封面
        certCoverUrl: "", //简介封面
        samplePlate: "", //证书样板
        samplePlateUrl: "", //证书样板
        certVideo: "", //简介
        certPhoto: "", //内容图片
        certPhotoUrl: "", //内容图片
      };

      this.$refs.DialogFromRef.resetFields();
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style>
</style>